import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Container, Row, Col } from 'react-bootstrap';

import heroScreenshot from '../images/promlens-hero-screenshot.png';

import ricardoLogo from '../images/ricardo-logo.svg';
import adidasLogo from '../images/adidas-logo.svg';
import soundcloudLogo from '../images/soundcloud-logo.svg';

import featureExplainVid from '../images/feature-explain.mp4';
import featureAutocompleteVid from '../images/feature-autocomplete.mp4';
import featureFormEditorVid from '../images/feature-formeditor.mp4';
import featureInlinePromQL from '../images/feature-inline-promql.gif';
import featureShareVid from '../images/feature-share.mp4';
import featureShowLabelValuesVid from '../images/feature-label-values.mp4';
import featureSnippets from '../images/feature-snippets.gif';
import featureTreeEditing from '../images/feature-tree-editing.gif';
import featureMetricsExplorerVid from '../images/feature-metrics-explorer.mp4';
import featureHintsAndActionsVid from '../images/feature-hints-and-actions.mp4';
import featureTreeViewErrorsVid from '../images/feature-treeview-errors.mp4';
import featureVisualizationVid from '../images/feature-visualization.mp4';

import promlensPromQLConnections from '../images/promlens-connections.svg';

import promlensLogo from '../images/promlens-logo.svg';
import prometheusLogo from '../images/prometheus-logo.svg';
import thanosLogo from '../images/thanos-logo.svg';
import cortexLogo from '../images/cortex-logo.svg';
import timescaleLogo from '../images/timescale-logo.svg';
import promlensTree from '../images/promlens-tree.png';

const featureVideo = (vid: string): React.ReactElement => (
  <div className="feature-video">
    <video playsInline muted autoPlay loop style={{ width: '100%' }}>
      <source src={vid} type="video/mp4" />
      <p>Sorry, your browser does not support videos.</p>
    </video>
  </div>
);

const IndexPage = () => (
  <Layout>
    <SEO title="PromLens, the query builder for PromQL" />
    <div className="landing-page">
      <div>
        <div
          style={{ maxWidth: 700, margin: 'auto', textAlign: 'center', fontSize: '1.2em', padding: '35px 35px 35px 35px' }}
        >
          <strong>Announcement on Oct 25, 2022:</strong> <br />
          <br />
          We are excited to announce that{' '}
          <a href="https://promlabs.com/" target="_blank" rel="noreferrer">
            PromLabs
          </a>{' '}
          and{' '}
          <a href="https://chronosphere.io/" target="_blank" rel="noreferrer">
            Chronosphere
          </a>{' '}
          have donated PromLens to the{' '}
          <a href="https://prometheus.io/" target="_blank" rel="noreferrer">
            Prometheus project
          </a>{' '}
          under an <strong>open-source license</strong>. PromLens is now available to anyone without a license key! Learn
          more in our{' '}
          <a href="https://promlabs.com/blog/2022/10/25/promlabs-and-chronosphere-open-source-the-promlens-query-builder" target="_blank" rel="noreferrer">
            announcement blog post about the open-sourcing
          </a>
          .
        </div>
      </div>
      <div className="hero hero-landing">
        <div className="hero-background"></div>
        <div className="hero-text">
          <h1>The power tool for querying Prometheus</h1>
          <p className="hero-subtitle">
            <strong>Build, understand, and fix</strong> your queries much more effectively with the ultimate{' '}
            <strong>query builder for PromQL</strong>
            <p className="pt-3">
              <i>Built by the co-founder of Prometheus and creator of PromQL</i>
            </p>
          </p>
        </div>
        <div className="action-buttons">
          <a className="action-button" href="https://demo.promlens.com/" target="_blank">
            Live Demo
          </a>
          <a className="action-button" href="https://github.com/prometheus/promlens/" target="_blank">
            Deploy Now
          </a>
        </div>
        <div className="hero-screenshot">
          <div className="fadeout"></div>
          <a href="https://demo.promlens.com/?l=OTuO7josUeU" target="_blank" rel="noreferrer">
            <img src={heroScreenshot} alt="Example of a shared PromLens page" title="Click to see a demo" />
          </a>
        </div>
      </div>
      <Container className="text-center section">
        <div className="users-showcase-title">Used by:</div>
        <Row>
          <Col md={4}>
            <div className="users-showcase-user">
              <div className="users-showcase-user-logo-helper"></div>
              <a href="https://www.ricardo.ch/">
                <img src={ricardoLogo} alt="Ricardo logo" />
              </a>
            </div>
          </Col>
          <Col md={4}>
            <div className="users-showcase-user">
              <div className="users-showcase-user-logo-helper"></div>
              <a href="https://www.adidas-group.com/">
                <img src={adidasLogo} alt="Adidas logo" />
              </a>
            </div>
          </Col>
          <Col md={4}>
            <div className="users-showcase-user">
              <div className="users-showcase-user-logo-helper"></div>
              <a href="https://soundcloud.com/">
                <img src={soundcloudLogo} alt="SoundCloud logo" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="feature-showcase-break text-center section">
        <Container fluid="xl">
          <h2>Take the guesswork out of your PromQL</h2>
          <ul className="my-5">
            <li>Don't remember the syntax?</li>
            <li>Trying to track down an error?</li>
            <li>Unsure what data you are working with?</li>
            <li>Having trouble understanding a complex query?</li>
            <li>Wondering why a query returns an empty result?</li>
            <li>Want best-in-class autocompletion, highlighting, and linting?</li>
          </ul>
          <p className="subtitle">
            Whether you are building queries for dashboards, alerts, or ad-hoc insights, PromLens takes the frustration out
            of working with PromQL and helps you build, understand, and fix your queries in a much more effective way.
          </p>
        </Container>
      </div>

      <Container fluid="xl" className="text-center section feature-showcase-wrapper">
        <div className="feature-showcase">
          <div className="feature-explanation">
            <h3>Edit confidently</h3>
            <p>
              Get best-in-class <strong>autocompletion</strong>, <strong>highlighting</strong>, and inline{' '}
              <strong>linting</strong> while typing an expression.
            </p>
            <p>
              The metric names, label names, label values, function names, operators, and modifiers, are{' '}
              <strong>completed and linted contextually</strong>.
            </p>
          </div>
          {featureVideo(featureAutocompleteVid)}
        </div>

        <Row className="feature-showcase">
          <Col md={{ span: 6, order: 'last' }} className="feature-explanation">
            <h3>Build visually</h3>
            <p>Create and modify PromQL queries using a form-based editor.</p>
            <p>
              <strong>No need to memorize</strong> or look up all PromQL language details. The form-based editor makes all
              query types and options <strong>accessible in a visual way</strong>.
            </p>
          </Col>
          <Col md={{ span: 6, order: 'first' }}>{featureVideo(featureFormEditorVid)}</Col>
        </Row>

        <div className="feature-showcase">
          <div className="feature-explanation">
            <h3>Debug &amp; fix</h3>
            <p>Enter any PromQL query and visualize all of its sub-expressions as a tree.</p>
            <p>
              Instantly see the <strong>number of results</strong>, <strong>available labels</strong>, and potential{' '}
              <strong>errors</strong>, at every part of the query.
            </p>
          </div>
          {featureVideo(featureTreeViewErrorsVid)}
        </div>

        <div className="feature-showcase">
          <div className="feature-explanation">
            <h3>X-ray your data</h3>
            <p>
              Get immediate deeper insights about the values of any label in the tree, along with their number of
              occurrences.
            </p>
          </div>
          {featureVideo(featureShowLabelValuesVid)}
        </div>

        <div className="feature-showcase">
          <div className="feature-explanation">
            <h3>Hints &amp; Actions</h3>
            <p>
              PromLens detects common query patterns and pitfalls and will offer you warning hints and actions that you can
              apply with a press of a button.
            </p>
          </div>
          {featureVideo(featureHintsAndActionsVid)}
        </div>
      </Container>

      <div className="feature-showcase-break text-center section">
        <Container fluid="xl">
          <h2>Connect to any PromQL-compatible system</h2>
          <p className="subtitle mb-5">
            PromLens works with any PromQL-compatible endpoint, like <a href="https://prometheus.io/">Prometheus</a> itself,{' '}
            <a href="https://thanos.io/">Thanos</a>, <a href="https://cortexmetrics.io/">Cortex</a>,{' '}
            <a href="https://www.timescale.com/">Timescale</a> /{' '}
            <a href="https://github.com/timescale/promscale">Promscale</a>, and other compatible systems.
          </p>
          <div className="promql-connection-diagram">
            <div className="promql-endpoints diagram-box">
              <img style={{ width: '30%' }} src={prometheusLogo} alt="Prometheus logo"></img>
              <img style={{ width: '50%' }} src={thanosLogo} alt="Thanos logo"></img>
              <img style={{ width: '30%' }} src={cortexLogo} alt="Cortex logo"></img>
              <img style={{ width: '55%' }} src={timescaleLogo} alt="Timescale logo"></img>
            </div>
            <div className="promql-connector-arrow-box">
              <div className="promql-connector-arrow"></div>
              <span className="promql-connector-arrow-text">query</span>
            </div>
            <div className="promlens-box diagram-box">
              <div className="promlens-box-top diagram-box">
                <img className="promlens-box-logo" src={promlensLogo} alt="PromLens logo"></img>
              </div>
              <div className="promlens-box-bottom">
                <img className="promlens-box-screenshot" src={promlensTree} alt="PromLens screenshot"></img>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <Container fluid="xl" className="text-center section feature-showcase-wrapper">
        <Row className="feature-showcase">
          <Col md={{ span: 6, order: 'last' }} className="feature-explanation">
            <h3>Visualize</h3>
            <p>
              Show any part of a query tree as a <strong>table or a graph</strong>.
            </p>
            <p>
              Whereas Prometheus can only execute an entire query, PromLens allows you to{' '}
              <strong>visualize any part of the query tree</strong>.
            </p>
          </Col>
          <Col md={{ span: 6, order: 'first' }}>{featureVideo(featureVisualizationVid)}</Col>
        </Row>

        <Row className="feature-showcase">
          <Col md={{ span: 6, order: 'first' }} className="feature-explanation">
            <h3>Learn &amp; Understand</h3>
            <p>
              Understand what each part of a query is doing, and <strong>how it works under the hood</strong>.
            </p>
            <p>
              The query explain view tells shows you how <strong>binary operators match series together</strong>, what
              functions do, and more.
            </p>
          </Col>
          <Col md={{ span: 6, order: 'last' }}>{featureVideo(featureExplainVid)}</Col>
        </Row>

        <div className="feature-showcase">
          <div className="feature-explanation">
            <h3>Explore</h3>
            <p>Explore all metrics along with their metadata.</p>
            <p>
              PromLens has a built-in metrics explorer that allows you to{' '}
              <strong>see and filter all available metric names</strong>, along with their{' '}
              <strong>metric type and documentation string</strong>.
            </p>
          </div>
          {featureVideo(featureMetricsExplorerVid)}
        </div>
      </Container>

      <div className="feature-showcase-break text-center section">
        <Container fluid="xl">
          <h2>Deploy PromLens</h2>
          <p className="subtitle mb-5">
            Deploy PromLens as a Docker container. Getting started with the free version is simple:
          </p>
          <pre className="codebox">
            <code>
              <span className="codebox-prompt">$ </span>docker run -p 8080:8080 prom/promlens
            </code>
          </pre>
          <p className="subtitle mt-5">
            <a href="https://github.com/prometheus/promlens">Learn more...</a>
          </p>
        </Container>
      </div>

      <Container fluid="xl" className="text-center section feature-showcase-wrapper">
        {/* <Row className="feature-showcase">
          <Col  md={{ span: 6, order: 'last' }}>
            <img src={featureFormEditor} alt="Animated form editor demonstration" />
          </Col>
          <Col  md={{ span: 6, order: 'last' }} className="feature-explanation">
            <h3>Debug &amp; Fix</h3>
            <p>Create and modify PromQL queries using a form-based editor.</p>
            <p>
              <strong>No need to memorize</strong> or look up all PromQL language details. The form-based editor makes all
              query types and options <strong>accessible in a visual way</strong>.
            </p>
          </Col>
        </Row> */}

        <div className="feature-showcase">
          <div className="feature-explanation">
            <h3>Share</h3>
            <p>Make any PromLens page shareable with all its state.</p>
            <p>
              Whether you are a newbie wanting to get help from an advanced user or an expert showing a query to a beginner,
              sharing a PromLens page is a great way to start learning and talking about queries together.
            </p>
          </div>
          <div>{featureVideo(featureShareVid)}</div>
        </div>
      </Container>

      <div className="feature-showcase-break text-center section">
        <Container fluid="xl">
          <h2>Connect to Grafana</h2>
          <p className="subtitle mb-3">
            PromLens can connect to your existing Grafana installation to let you select and query Prometheus datasources
            configured in Grafana.
          </p>
        </Container>
      </div>

      <div className="bottom-actions text-center section">
        <h2>Get started with PromLens today:</h2>
        <div className="action-buttons">
          <a className="action-button" href="https://demo.promlens.com/">
            Live Demo
          </a>
          <a className="action-button" href="https://github.com/prometheus/promlens">
            Deploy Now
          </a>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
